//export global _paq array upon which changes will be tracked.
define(['piwik' , 'jquery'], function (piwik , $) {
    "use strict";
    
     
    
/*    if (typeof JSON2 == 'object' && typeof JSON2.stringify == 'undefined' && typeof window.JSON === 'object' && window.JSON.stringify && window.JSON.parse) {
    	window.JSON2 = window.JSON;
    }*/
    _paq.push(["setTrackerUrl", tracking_server]);
    _paq.push(["setSiteId", +tracking_id_site]);
    _paq.push(['disableCookies']);
    _paq.push(["trackPageView"]);


  //  _paq.push(["enableLinkTracking"]);
    $(function(){


        // this is to track site search.
        $('body').on('click' ,'#searchButton', function(){
            if($('#searchString').val()){
                _paq.push(['trackSiteSearch' ,$('#searchString').val()]);
            }
        });
        var absolutePath = function(href) {
            var link = document.createElement("a");
            link.href = href;
            // working in chrome,  IE11 and IE10
            return link.href;
        }
       var dl_rex = new RegExp("(pdf|xls|txt|zip|xml)$");

       $('body').on('click', 'a' ,function($event){

           if($(this).attr('href')){ // hs href attribute
               if(dl_rex.test( $(this).attr('href'))){
                _paq.push(['trackLink' , absolutePath($(this).attr('href')) , 'download']);
               }else{
                _paq.push(['trackLink' , absolutePath($(this).attr('href')) , 'link']);
               }
            }else{
                // track as event , example for share links.
            }
    });
    });
    return _paq;
});
